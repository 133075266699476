import { ColumnDefinition } from "@tager/admin-ui";

import { LeadType } from "@/modules/leads/typings";
import { LEADS_STATUSES } from "@/modules/leads/constants";
import {getLeadsViewUrl} from "@/modules/leads";

export const COLUMN_DEFS: Array<ColumnDefinition<LeadType>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "70px",
    type: "link",
    options: {
      disableCopyButton: true,
    },
    format: ({ row }) => ({
      url: getLeadsViewUrl(row.id),
      text: String(row.id),
    }),
  },
  {
    id: 2,
    name: "Дата, время",
    field: "createdAt",
    type: "datetime",
    width: "168px",
  },
  {
    id: 1,
    name: "Статус",
    field: "status",
    format: ({ row }) => {
      return (
        LEADS_STATUSES.find((item) => item.value === row.status)?.label ||
        "Неизвестный"
      );
    },
    width: "120px",
  },
  {
    id: 5,
    name: "Телефон",
    field: "phone",
    width: "160px",
    format: ({ row }) => {
      return row.phone ? row.phone.replace("+", "") : "*** ** *** ** **";
    },
  },
  {
    id: 3,
    name: "Тип",
    field: "type",
    headStyle: { width: "150px", maxWidth: "150px" },
  },
  {
    id: 4,
    name: "Имя",
    field: "name",
  },
  {
    id: 9,
    name: "",
    field: "actions",
    width: "60px",
  },
];

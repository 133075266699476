import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backUrl,
        "hide-submit-buttons": true
      }, null, 8, ["back-href"])
    ]),
    default: _withCtx(() => [
      (!!_ctx.model)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_FieldValue, {
              label: "ID",
              value: String(_ctx.model.id)
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Дата / Время",
              value: String(_ctx.model.createdAt),
              type: "datetime"
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Статус",
              value: String(_ctx.model.status)
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Тип",
              value: String(_ctx.model.type)
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Имя",
              value: String(_ctx.model.name)
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Телефон",
              value: String(_ctx.model.phone)
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Параметры",
              type: "list",
              value: _ctx.model.params.map(item => item.param + ': ' + (item.value ?? ''))
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Ссылка",
              src: _ctx.model.referrerUrl,
              text: _ctx.model.referrerUrl,
              type: "link"
            }, null, 8, ["src", "text"]),
            _createVNode(_component_FieldValue, {
              label: "Ответ от API",
              value: JSON.stringify(_ctx.model.responseData),
              type: "json"
            }, null, 8, ["value"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}
import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";

import { getLeadsUrl } from "@/modules/leads/routes";

type LinkKey = "HOME" | "LEADS" | "ADMIN_ROLES" | "ADMIN_ADMINS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },
  LEADS: {
    url: getLeadsUrl(),
    text: "Заявки",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
});


import { defineComponent } from "vue";

import { useDataTable, DataTable, BaseButton, EyeIcon } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { LeadType } from "@/modules/leads/typings";
import { getLeadsList } from "@/modules/leads/requests";

import { COLUMN_DEFS } from "./LeadsList.helpers";
import {getLeadsViewUrl} from "@/modules/leads";

export default defineComponent({
  name: "LeadsList",
  methods: {getLeadsViewUrl},
  components: {
    Page,
    DataTable,
    BaseButton,
    EyeIcon
  },
  setup() {
    const {
      isLoading: isDataLoading,
      rowData: companyList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<LeadType>({
      fetchEntityList: (params) =>
        getLeadsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Заявки",
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: companyList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});


import { computed, defineComponent, onMounted } from "vue";

import { useResource } from "@tager/admin-services";
import { NavigationGrid, type NavigationGridItem } from "@tager/admin-ui";

import { getLinks } from "@/constants/links";
import { getLeadsCount } from "@/modules/leads";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const links = computed(() => getLinks());

    const [
      fetchLeadsCount,
      { data: leadsCountData, status: leadsCountDataStatus },
    ] = useResource({
      fetchResource: () => getLeadsCount(),
      initialValue: null,
      resourceName: "Leads Count",
    });

    onMounted(() => {
      fetchLeadsCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.LEADS.text,
          url: links.value.LEADS.url,
          total: {
            value: leadsCountData.value?.count ?? 0,
            status: leadsCountDataStatus.value,
          },
        },
        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
        },
      ];
    });

    return {
      navItemList,
    };
  },
});

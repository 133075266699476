
import { computed, defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";

import { FormFooter, FieldValue } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { Nullable, useResource } from "@tager/admin-services";

import { LeadFullType } from "@/modules/leads/typings";
import { getLeadsDetailed } from "@/modules/leads/requests";
import { getLeadsUrl } from "@/modules/leads";

export default defineComponent({
  name: "BrandForm",
  components: {
    Page,
    FormFooter,
    FieldValue,
  },
  setup() {
    const route = useRoute();

    const leadId = computed<number>(() => +route.params.id);

    const [fetchLead, { data: model, loading: isLeadLoading }] = useResource<
      Nullable<LeadFullType>
    >({
      fetchResource: () => getLeadsDetailed(leadId.value),
      initialValue: null,
      resourceName: "Лид",
    });

    onMounted(fetchLead);

    return {
      pageTitle: computed<string>(() => "Заявка № " + leadId.value),
      isContentLoading: isLeadLoading,
      backUrl: getLeadsUrl(),
      model
    };
  },
});

import { OptionType } from "@tager/admin-ui";

export const LEADS_STATUSES: Array<OptionType<string>> = [
  {
    value: "SUCCESS",
    label: "Обработана",
  },
  {
    value: "WAITING",
    label: "В ожидании",
  },
  {
    value: "FAILURE",
    label: "Ошибка",
  },
  {
    value: "PROCESSING",
    label: "В процессе",
  },
];

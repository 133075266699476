import { request, ResponseBody } from "@tager/admin-services";

import {LeadFullType, LeadType} from "@/modules/leads/typings";

export interface CountData {
  count: number;
}

export function getLeadsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/leads/count" });
}

export function getLeadsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<LeadType>>> {
  return request.get({ path: "/admin/leads", params });
}

export function getLeadsDetailed(id: number): Promise<ResponseBody<LeadFullType>>{
  return request.get({
    path: `/admin/leads/${id}`
  });
}

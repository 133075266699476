import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";

import LeadsList from "./LeadsList";
import LeadsView from "./LeadsView";

export function getLeadsUrl(): string {
  return "/leads";
}

export function getLeadsViewUrl(leadId: number): string {
  return "/leads/" + leadId;
}

export const LEADS_LIST_ROUTE: RouteRecordRaw = {
  path: "/leads",
  component: LeadsList,
  name: "Leads List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().LEADS],
  },
};
export const LEADS_VIEW_ROUTE: RouteRecordRaw = {
  path: "/leads/:id",
  component: LeadsView,
  name: "Leads View",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().LEADS],
  },
};
